body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

.app {
  width: 100vw;
  height: 100vh;
  padding: 0 !important;
  max-width: none !important;
  max-height: none !important;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}

.token-ID input {
  text-indent: 0.75rem;
}

.MuiInputBase-root-MuiInput-root:before {
  border-bottom: white !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.CardContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.CardContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 25px solid #24187e;
  position: absolute;
  top: -14px;
  right: 16px;
}

.swiper-slide {
  width: auto !important;
  height: auto !important;
}

.MuiFilledInput-input {
  border-radius: 0.5rem;
  background: #241c81 !important;
  color: white !important;
}

.MuiFilledInput-input::placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}

.MuiFilledInput-root::before {
  display: none !important;
}

.MuiFilledInput-root::after {
  display: none !important;
}
